import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

import s from 'components/app-layout/AppLayout.scss';

export default () => (
  <>

    <Helmet title="Cloud Services" />

    <div className={s.bgvideo}></div>
    
    <video className="video-background" preload="auto" loop autoPlay muted  playsInline>
    <source src="https://aguila-cloud.s3.amazonaws.com/bgvid.webm" type="video/webm" />
    <source src="https://aguila-cloud.s3.amazonaws.com/bg.mp4" type="video/mp4" />
    <source src="https://aguila-cloud.s3.amazonaws.com/bgvid.ogv" type="video/ogg" />
    Your browser does not support the video tag.
    </video>
    <Intro>
      En Headways Digital nuestro objetivo es solucionar tus desafíos tecnológicos más difíciles. Nos apasiona la tecnología y sabemos cómo hacerlo. Implementamos toda una <Highlight>transformación digital</Highlight> con infraestructuras escalables hechas a la medida. &nbsp;
    </Intro>

    <BlockText
      heading=""
      description={(
        <>
          Engineering Excellence. Agile and Responsive.
          <br></br>Contáctanos: <a className={s.mailcontact} href="mailto:info@headways.digital" rel="noopener noreferrer">info@headways.digital</a>.
        </>
      )}
    />
  </>
);
